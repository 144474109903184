import TermsCluster from '@components/common/modal/terms-cluster';
import Grid from '@components/grid';
import Input from '@components/ui/input';
import React, { ReactElement } from 'react';
import Text from '@components/ui/text';
import { useForm } from 'react-hook-form';
import Container from '@components/ui/container';
import IndexText from '@components/index-text';
import { IoMdReturnRight } from 'react-icons/io';
import { onSubmitForm, TFormFields } from '@lib/onSubmitForm';
import { useSnapshot } from 'valtio';
import { formStore } from '@store/form-store';
import SubmittedState from '@components/ui/submitted-state';
import FormLoadingOverlay from '@components/ui/form-loading-overlay';
import FormSubmitButton from '@components/ui/form-submit-button';
import toast from 'react-hot-toast';
import { checkIfHuman } from '@lib/checkIfHuman';
import BrowserSelect from '@components/ui/browser-select';

function VerticalLine({ className }: { className?: string }) {
  return (
    <div
      className={`hidden h-full lg:row-span-1 lg:block bg-telesoft backdrop-blur-sm opacity-60 ${className}`}
    ></div>
  );
}

export default function Form(block: any): ReactElement {
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const snap = useSnapshot(formStore);
  const isLoading = snap.isLoading;
  const reRef = React.useRef<any>(null);
  const { title, subTitle, description, form } = block;

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<TFormFields>();

  if (
    snap.fields.firstName &&
    snap.fields.lastName &&
    snap.fields.email &&
    snap.fields.phone
  ) {
    setValue('firstName', snap.fields.firstName);
    setValue('lastName', snap.fields.lastName);
    setValue('email', snap.fields.email);
    setValue('phone', snap.fields.phone);
  }
  if (snap.fields.company) {
    setValue('company', snap.fields.company);
  }

  function isValidMailAddress(email: string) {
    let forbiddenDomains = [
      'outlook.com',
      'outlook.co.uk',
      'yahoo.co.uk',
      'yahoo.com',
      'hotmail.com',
      'hotmail.co.uk',
      'sky.com',
      'icloud.com',
      'mac.com',
      'gmail.com',
    ];
    if (forbiddenDomains.some((v: string) => email.toLowerCase().includes(v))) {
      return false;
    }
    return true;
  }

  async function onSubmit(fields: TFormFields) {
    const isHuman = await checkIfHuman(reRef);
    if (!isHuman) {
      toast.error(
        "We can't verify the authenticity of this request. Please try submitting the form once more.",
        { id: 'RECAPTCHA' }
      );
    }
    if (fields?.email) {
      const isValidEmail = isValidMailAddress(fields.email);
      console.log('email is valid: ', isValidEmail);
    }
    formStore.isLoading = true;
    onSubmitForm(
      'Form name here',
      reRef,
      fields,
      'form-submissions',
      setIsSubmitted,
      reset
    );
  }

  return (
    <Container className="h-full w-full">
      <Grid
        id="demonstration-form"
        className="lg:grid-rows-2 w-full h-full flex flex-col z-10"
      >
        <div className="hidden h-full lg:flex lg:row-span-1 lg:col-span-5 bg-black bg-opacity-60 backdrop-blur-md border border-telesoft p-8 flex-col justify-center items-start text-white">
          {subTitle && (
            <IndexText index={subTitle} type="text" className="ml-6" />
          )}
          <div className="flex justify-start items-center">
            <IoMdReturnRight className="mr-2 text-white" />
            <Text variant="mediumHeading" className="text-white">
              {title}
            </Text>
          </div>
          <Text
            variant="body"
            className="text-white opacity-70 mt-4 max-w-md ml-6"
          >
            {description}
          </Text>
        </div>
        <VerticalLine className="lg:row-span-2" />
        <VerticalLine className="lg:row-span-2" />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="lg:row-span-2 col-span-full min-h-[800px] w-full lg:col-span-5 bg-white p-8 md:p-10 lg:p-14 flex flex-col grow justify-center"
          noValidate
        >
          {isSubmitted && (
            <SubmittedState className="h-full flex flex-col justify-center items-center" />
          )}
          {!isSubmitted && (
            <div className="flex flex-col space-y-4">
              <fieldset className="relative space-y-4 mb-4">
                <FormLoadingOverlay show={isLoading} />
                {form?.fields &&
                  form.fields.length > 0 &&
                  form.fields.map(({ field }: { field: any }, i: number) => {
                    const { name, label, isRequired, errorMessage, type } =
                      field;
                    let errObj = errors;
                    let errKey = name;
                    let errMsg;
                    // @ts-ignore
                    ({ [errKey]: errMsg } = errObj);
                    if (field.type === 'radio') {
                      return (
                        <BrowserSelect
                          options={field.options}
                          labelKey={label}
                          {...register(name)}
                          errorKey={errors && errMsg?.message}
                        />
                      );
                    }
                    return (
                      <Input
                        key={`field--map--${i}`}
                        labelKey={isRequired ? `${label}*` : label}
                        type={type}
                        variant="solid"
                        {...register(name, {
                          required: isRequired ? errorMessage : undefined,
                          validate: {
                            workEmail: (v) => {
                              if (name !== 'email') {
                                return true;
                              }
                              const isValidEmail = isValidMailAddress(v);
                              if (!isValidEmail) {
                                return 'Please use a work email';
                              }
                              return true;
                            },
                          },
                          pattern:
                            name === 'email'
                              ? {
                                  value:
                                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                  message: 'Please use a valid email',
                                }
                              : undefined,
                        })}
                        errorKey={errors && errMsg?.message}
                      />
                    );
                  })}
              </fieldset>
              <FormSubmitButton
                buttonText={form?.options?.buttonText}
                isLoading={isLoading}
              />
              <TermsCluster />
            </div>
          )}
        </form>
        <VerticalLine />
        <VerticalLine />
        <VerticalLine />
        <VerticalLine />
        <VerticalLine />
      </Grid>
    </Container>
  );
}
