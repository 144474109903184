import Grid from '@components/grid';
import React, { ReactElement } from 'react';
import Text from '@components/ui/text';
import Container from '@components/ui/container';
import IndexText from '@components/index-text';
import { IoMdReturnRight } from 'react-icons/io';
import FormComponent, { TForm } from '@components/FormComponent';

function VerticalLine({ className }: { className?: string }) {
  return (
    <div
      className={`hidden h-full lg:row-span-1 lg:block bg-telesoft backdrop-blur-sm opacity-60 ${className}`}
    ></div>
  );
}

export default function DemonstrationForm({
  title,
  subTitle,
  description,
  form,
}: {
  title: string;
  subTitle?: string;
  description: string;
  form: TForm;
}): ReactElement {
  return (
    <Container className="h-full w-full">
      <Grid
        id="demonstration-form"
        className="lg:grid-rows-2 w-full h-full flex flex-col z-10"
      >
        <div className="hidden h-full lg:flex lg:row-span-1 lg:col-span-5 bg-black bg-opacity-60 backdrop-blur-md border border-telesoft p-8 flex-col justify-center items-start text-white">
          <IndexText
            index={subTitle || 'PRODUCT DISCOVERY'}
            type="text"
            className="ml-6"
          />
          <div className="flex justify-start items-center">
            <IoMdReturnRight className="mr-2 text-white" />
            <Text variant="mediumHeading" className="text-white">
              {title}
            </Text>
          </div>
          <Text
            variant="body"
            className="text-white opacity-70 mt-4 max-w-md ml-6"
          >
            {description}
          </Text>
        </div>
        <FormComponent
          form={form}
          className="lg:row-span-2 col-span-full min-h-[800px] w-full lg:col-span-5 bg-white p-8 md:p-10 lg:p-14 flex flex-col grow justify-center"
        />
        <VerticalLine className="lg:row-span-full" />
        <VerticalLine className="lg:row-span-full" />
        <VerticalLine />
        <VerticalLine />
        <VerticalLine />
        <VerticalLine />
        <VerticalLine />
      </Grid>
    </Container>
  );
}
