import React, { ReactElement } from 'react';
import Container from '@components/ui/container';
import useHasMounted from '@utils/use-has-mounted';
import Script from 'next/script';

// TODO: Implement paylaod/types package
interface TypeformEmbedBlockProps {
  title: string;
  formId: string;
  options: {
    height?: number;
  };
}

export default function TypeformEmbed({
  title,
  formId,
  options,
}: TypeformEmbedBlockProps): ReactElement {
  const defaultHeight = 500;
  const { height = defaultHeight } = options;

  const hasMounted = useHasMounted();
  if (!hasMounted) return null!;

  return (
    <Container>
      <Script src="//embed.typeform.com/next/embed.js" />
      <div
        data-tf-widget={formId}
        data-tf-iframe-props={`title=${title}`}
        data-tf-medium="snippet"
        style={{ height: `${height}px` }}
        className={`w-full h-[calc(100vh-78px)] focus-none`}
      />
    </Container>
  );
}
