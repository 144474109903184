import React, { ReactElement } from 'react';
import { ASSET_URL } from 'lib/constants';
import DemonstrationForm from './DemonstrationForm';

export default function DemonstrationFormHero(block: any): ReactElement {
  const { title, subTitle, description, form, options, video } = block;

  return (
    <div
      className={`min-h-screen flex justify-center items-center relative bg-heading ${
        options.padding ? 'py-24' : 'py-0'
      } overflow-hidden`}
    >
      {options.darken && <div className="absolute inset-0 bg-heading"></div>}
      <div className="absolute inset-0 bg-telesoft mix-blend-soft-light"></div>
      <div className="absolute inset-0 opacity-40">
        <video
          id="vid"
          loop
          autoPlay
          playsInline
          muted
          width="100%"
          className="object-cover"
          height="100%"
        >
          <source
            src={`${
              video?.filename
                ? ASSET_URL + '/' + video.filename
                : '/assets/video/home-test.mp4'
            }`}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <DemonstrationForm
        title={title}
        subTitle={subTitle}
        description={description}
        form={form}
      />
    </div>
  );
}
