import HeroBannerCard from '@components/common/hero-banner-card';
import QuiteCoolLines from '@components/HeroLines';
import Carousel from '@components/ui/carousel/carousel';
import Container from '@components/ui/container';
import HeroWithBackgroundVideo from '@containers/hero-with-background-video';
import React, { ReactElement } from 'react';
import { ASSET_URL } from 'lib/constants';
import { SwiperSlide } from 'swiper/react';

function HeroSliderFullscreen(block: any): ReactElement {
  const { slides, video } = block;

  return (
    <HeroWithBackgroundVideo
      padding={false}
      source={
        video?.filename
          ? ASSET_URL + '/' + video.filename
          : '/assets/video/telesoft-home-hero-bg.mp4'
      }
    >
      <div className="z-20 relative overflow-hidden">
        <Container className="h-screen">
          <QuiteCoolLines />
          <Carousel
            pagination={{
              clickable: false,
            }}
            autoplay={true}
            className="h-full flex relative justify-center items-center"
            paginationVariant="hidden"
          >
            {slides.map((slide: any, idx: number) => {
              return (
                <SwiperSlide key={`slide--key${slide.id}`}>
                  <HeroBannerCard
                    idx={idx}
                    slide={slide}
                    href={`${slide.slug}`}
                  />
                </SwiperSlide>
              );
            })}
          </Carousel>
        </Container>
      </div>
    </HeroWithBackgroundVideo>
  );
}

export default HeroSliderFullscreen;
