import Grid from '@components/grid';
import ProductLinkItem from '@components/ProductLinkItem';
import Container from '@components/ui/container';
import Text from '@components/ui/text';
import React, { ReactElement } from 'react';

function QuickLinksCallout(block: any): ReactElement {
  const {
    heading,
    statement = 'Let us solve your security challenges whether on-prem or managed.',
    links,
  } = block;

  return (
    <Container>
      <Grid>
        <div className="col-span-full md:col-span-4 lg:col-span-6 text-black lg:bg-accent-1 lg:p-8 flex flex-col justify-start items-start">
          <h5>{heading}</h5>
          <div className="w-full h-[1px] bg-black my-4" />

          {links.map(({ link }: any, i: number) => (
            <ProductLinkItem key={`link--map--${i}`} link={link} />
          ))}
        </div>

        <div className="col-span-full md:col-span-4 whitespace-pre-wrap lg:col-span-6 text-black">
          <Text variant="body">{statement}</Text>
        </div>
      </Grid>
    </Container>
  );
}

export default QuickLinksCallout;
