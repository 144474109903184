import Grid from '@components/grid';
import React, { ReactElement } from 'react';
import Text from '@components/ui/text';
import Container from '@components/ui/container';
import IndexText from '@components/index-text';
import { IoMdReturnRight } from 'react-icons/io';
import { IoCheckmark } from 'react-icons/io5';
import FormComponent, { TForm } from '@components/FormComponent';

interface ILeadCapture {
  title?: string;
  subTitle?: string;
  description?: string;
  formName: string;
  items: [{ text: string }];
  options: any;
  form: TForm;
}

function VerticalLine({ className }: { className?: string }) {
  return (
    <div
      className={`hidden h-full lg:row-span-1 lg:block bg-telesoft backdrop-blur-sm opacity-60 ${className}`}
    ></div>
  );
}

export default function LeadCaptureForm(block: ILeadCapture): ReactElement {
  const {
    title,
    subTitle = 'Threat Hunting',
    description,
    items,
    form,
  } = block;

  return (
    <Container className="h-full w-full">
      <Grid
        id="demonstration-form"
        className="lg:grid-rows-2 w-full h-full flex flex-col z-10"
      >
        <div className="hidden h-full lg:flex lg:row-span-1 lg:col-span-7 bg-black bg-opacity-60 backdrop-blur-md border border-white p-8 lg:p-12  flex-col justify-center items-start text-white">
          <IndexText index={subTitle} type="text" className="ml-6" />
          <div className="flex justify-start items-center">
            <IoMdReturnRight className="mr-2 text-white" />
            <Text variant="mediumHeading" className="text-white">
              {title}
            </Text>
          </div>
          <div className="mt-8">
            <Text variant="body" className="text-white">
              {description}
            </Text>
          </div>
          <div className="mt-8 grid grid-cols-2 gap-4 max-w-xl">
            {items &&
              items?.length > 0 &&
              items.map((item: any) => {
                return (
                  <ListItem key={`list--item--map--${item.text}`}>
                    {item.text}
                  </ListItem>
                );
              })}
          </div>
        </div>
        <FormComponent form={form} className="" />
        <VerticalLine />
        <VerticalLine />
        <VerticalLine />
        <VerticalLine />
        <VerticalLine />
        <VerticalLine />
        <VerticalLine />
      </Grid>
    </Container>
  );
}

export function ListItem({ children }: { children: React.ReactNode }) {
  return (
    <div className="col-span-full md:col-span-1 flex justify-start items-center">
      <div className="w-4 h-auto mr-3 border-b border-white border-t border-black">
        <IoCheckmark className="text-telesoft" />
      </div>
      <Text className="font-medium text-white">{children}</Text>
    </div>
  );
}
