import Text from '@components/ui/text';
import React, { ReactElement } from 'react';
import Image from 'next/image';
import { ASSET_URL } from 'lib/constants';
import Grid from '@components/grid';
import Container from '@components/ui/container';
import cn from 'classnames';
import Divider from './Divider';
import CMSLink from '@components/CMSLink';
import Button from '@components/ui/button';
import RicherText from './RicherText';

function ContentImage(block: any): ReactElement {
  const { title, description, image, options } = block;
  const {
    isSubtitle,
    isButton,
    link,
    textAlignment,
    theme,
    subTitle,
    titleColour,
    overlayOpacity,
    lines = true,
  } = options;
  return (
    <>
      <Divider withLine={lines} />
      <Container>
        <Grid>
          <div
            className={
              'col-span-full relative min-h-[442px] p-8 md:p-12 lg:p-20 bg-accent-1 flex flex-col justify-center items-start'
            }
          >
            <div className="absolute inset-0 z-10 mix-blend-soft-light bg-gradient-to-b from-telesoft to-transparent" />
            <div
              style={{ opacity: `${overlayOpacity}%` }}
              className={cn(`absolute inset-0 z-10`, {
                'bg-white': theme === 'dark',
                'bg-black': theme === 'light',
              })}
            />
            <div
              className={cn(`mx-auto z-10 flex flex-col justify-center my-10`, {
                'text-center items-center': textAlignment === 'text-center',
                'text-left items-start': textAlignment === 'text-left',
              })}
            >
              <div className="pl-4 md:pl-8 lg:pl-16">
                {isSubtitle && (
                  <h4
                    className={cn('mb-3', {
                      'text-white': theme === 'light',
                      'text-black': theme === 'dark',
                    })}
                  >
                    {subTitle}
                  </h4>
                )}
                <Text
                  variant="subHeading"
                  className={cn('pb-4 md:pb-6 lg:pb-8 w-full', {
                    'text-telesoft': titleColour === 'blue',
                    'text-white': titleColour === 'light',
                    'text-black': titleColour === 'black',
                  })}
                >
                  {title}
                </Text>
              </div>
              <RicherText
                container={true}
                theme={theme}
                content={description}
                className={cn('max-w-4xl', {
                  'text-white': theme === 'light',
                  'text-black': theme === 'dark',
                })}
              />
              {isButton && (
                <CMSLink {...link}>
                  <Button
                    variant={'cta'}
                    invert={theme === 'light'}
                    className={cn(
                      'ml-4 md:ml-8 lg:ml-16 mt-6 md:mt-7 lg:mt-8',
                      {
                        'text-white': theme === 'light',
                        'text-black': theme === 'dark',
                      }
                    )}
                  >
                    {link?.label ? link.label : 'LEARN MORE'}
                  </Button>
                </CMSLink>
              )}
            </div>
            <div className="absolute inset-0">
              <Image
                src={
                  image
                    ? ASSET_URL + '/' + image.filename
                    : `/assets/images/telesoft-abstract-33.jpeg`
                }
                alt={image.alt || ''}
                loading="lazy"
                className="object-cover object-center"
                layout="fill"
              />
            </div>
          </div>
        </Grid>
      </Container>
      <Divider withLine={lines} />
    </>
  );
}

export default ContentImage;
