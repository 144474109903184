import Divider from '@blocks/Divider';
import React, { ReactElement } from 'react';
import Grid from './grid';
import Template from './layout/template';
import CallToActionSupportSection from './sections/call-to-action-support-section';
import SolutionsHeroSection from './sections/solutions-hero-section';
import SolutionListItem from './solution-list-item';
import Container from './ui/container';

export default function NotFound(): ReactElement {
  return (
    <Template>
      <SolutionsHeroSection
        title="404"
        description={{
          short: "This page doesn't exist or has been removed.",
          long: "Our servers indicate there's nothing here. This URL either doesn't exist or has been removed.",
        }}
        imageUrl="/assets/images/404.jpg"
      />

      <Divider withLine={true} />
      <Container>
        <Grid className="lg:pb-[280px] xl:pb-[300px]">
          <SolutionListItem
            index={0}
            href="/products/tdac"
            imageUrl={'/assets/images/telesoft-abstract-50.jpeg'}
            title={'TDAC'}
            description={'All-in-one network protection.'}
          />
          <SolutionListItem
            index={1}
            href="/solutions/enterprise"
            imageUrl={'/assets/images/telesoft-abstract-33.jpeg'}
            title={'Enterprise'}
            description={'On-prem or managed solutions.'}
          />
          <SolutionListItem
            index={3}
            href="/blog"
            imageUrl={'/assets/images/telesoft-abstract-26.jpeg'}
            title={'Blog: Nucleus'}
            description={'Making digital secure.'}
          />
        </Grid>
      </Container>
      <Divider />

      <CallToActionSupportSection />
    </Template>
  );
}
