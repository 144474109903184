import Grid from '@components/grid';
import Container from '@components/ui/container';
import { ASSET_URL } from '@lib/constants';
import Image from 'next/image';

export default function FullWidthImage(block: any) {
  const { image } = block;
  return (
    <Container>
      <Grid>
        <div
          style={{ height: '100%', width: '100%', position: 'relative' }}
          className="col-span-full"
        >
          <Image
            src={
              image?.filename
                ? ASSET_URL + '/' + image.filename
                : '/assets/images/tdac-enterprise-diagram.svg'
            }
            alt={image?.alt || ''}
            loading="lazy"
            className="w-full"
            height={image.height}
            width={image.width}
            objectFit="contain"
          />
        </div>
      </Grid>
    </Container>
  );
}
