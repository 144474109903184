import React from 'react';
import { motion } from 'framer-motion';

export default function SubmittedState({
  title = 'Request submitted successfully.',
  description = 'Thank you for submitting your information.',
  className,
}: {
  title?: string;
  description?: string;
  className?: string;
}) {
  const stateDiv = React.useRef(null);
  const scrollToBtn = React.useRef(null);
  React.useEffect(() => {
    if (scrollToBtn && scrollToBtn.current) {
      //@ts-ignore
      scrollToBtn.current.click();
    }
  }, []);
  const scrollTo = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref && ref.current /* + other conditions */) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      ref={stateDiv}
      className={`py-6 px-10 bg-accent-1 text-left ${className}`}
    >
      <h3>{title}</h3>
      <p className="mt-2">{description}</p>
      <button
        hidden
        ref={scrollToBtn}
        type="button"
        onClick={() => scrollTo(stateDiv)}
      >
        scroll to
      </button>
    </motion.div>
  );
}
