import React, { ReactElement } from 'react';
import cn from 'classnames';
import Grid from '@components/grid';
import Button from '@components/ui/button';
import Text from '@components/ui/text';
import Container from '@components/ui/container';
import CMSLink from '@components/CMSLink';

export default function DarkFooterCTA(block: any): ReactElement {
  const { title, buttons, options } = block;
  const { variant = 'black' } = options;
  return (
    <Container>
      <div
        className={cn(
          `relative p-8 md:p-12 lg:p-20 flex flex-col justify-center items-center`,
          {
            'bg-black': variant === 'black',
            'bg-telesoft': variant === 'blue',
          }
        )}
      >
        <Grid className="w-full">
          <div className="col-span-full">
            <Text
              variant="pageHeading"
              className={cn({
                'text-white': variant === 'black',
                'text-black': variant === 'blue',
              })}
            >
              {title}
            </Text>
          </div>

          {buttons &&
            buttons.map((btn: any) => {
              const { link } = btn;
              return (
                <CMSLink
                  key={btn.id}
                  {...link}
                  className={cn('col-span-4 lg:col-span-6', {
                    'text-white': variant === 'black',
                  })}
                >
                  <Button
                    variant={variant === 'black' ? 'cta' : 'flat'}
                    invert={variant === 'black' ? true : false}
                    className={'w-full'}
                  >
                    {link.label}
                  </Button>
                </CMSLink>
              );
            })}
        </Grid>
      </div>
    </Container>
  );
}
