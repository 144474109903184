import { motion } from 'framer-motion';
import cn from 'classnames';
import Button from '@components/ui/button';
import Text from '@components/ui/text';
import Grid from '@components/grid';
import IndexText from '@components/index-text';
import { VscChevronRight } from 'react-icons/vsc';
import CMSLink from './CMSLink';

const heroContentVariant = {
  initial: {
    opacity: 0,
    y: 28,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      ease: 'easeInOut',
      staggerChildren: 0.2,
      duration: 0.4,
    },
  },
};

function BannerCard({ title, description, terminal, idx, buttons }: any) {
  return (
    <Grid className="w-full">
      <motion.div
        variants={heroContentVariant}
        className="flex flex-col justify-start items-start relative col-span-4 lg:col-span-7 text-white z-10 h-full"
      >
        {terminal && <IndexText index={idx || 0} />}
        <Text variant="superHeading" className="text-white">
          <span className="leading-snug">{title}</span>
        </Text>
      </motion.div>

      <motion.div
        variants={heroContentVariant}
        className="flex flex-col justify-start relative items-start bg-opacity-50 lg:pt-4 lg:mt-4 col-span-4 lg:col-span-5 xl:col-span-5 xl:col-end-13 text-white z-10"
      >
        <Text className="mt-4 text-white text-sm md:text-base lg:text-lg">
          {description}
        </Text>
      </motion.div>

      <motion.div
        variants={heroContentVariant}
        className={cn(
          'col-span-full text-white md:col-span-4 md:col-start-5 lg:col-start-8 lg:col-end-13',
          {
            'grid gap-8 grid-rows-2 md:grid-cols-2': buttons?.length > 1,
          }
        )}
      >
        {buttons?.length > 0 &&
          buttons.map(({ link }: { link: any }, i: number) => {
            return (
              <CMSLink key={`btn--key--map-${i}`} {...link}>
                <Button
                  variant="cta"
                  invert={true}
                  className={cn('w-full relative col-span-full', {
                    'col-span-1': buttons?.length > 1,
                  })}
                >
                  <VscChevronRight className="mr-1" />
                  {link.label}
                </Button>
              </CMSLink>
            );
          })}
      </motion.div>
    </Grid>
  );
}

export default BannerCard;
