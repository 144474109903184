import { ROUTES } from '@constants/routes';
import { useUI } from '@contexts/ui.context';
import React, { ReactElement } from 'react';
import Link from 'next/link';

export default function TermsCluster(): ReactElement {
  const { closeModal } = useUI();
  return (
    <div className="mt-8">
      <p className="text-xs sm:text-sm">
        By submitting this form you agree to our{' '}
        <button
          type="button"
          onClick={() => {
            closeModal();
            window.open(ROUTES.TERMS, '_blank');
          }}
          className="linkDefault"
        >
          Terms & Conditions
        </button>
        .
      </p>
      <br />
      <p className="text-xs sm:text-sm">
        We do not share any details with third parties. Please see our{' '}
        <Link href="/privacy-policy">
          <a className="linkDefault">Privacy Policy</a>
        </Link>{' '}
        in relation to how we handle this information.
      </p>
    </div>
  );
}
