import Grid from '@components/grid';
import IntHighlightItem from '@components/int-highlight-item';
import Container from '@components/ui/container';
import React, { ReactElement } from 'react';

// interface Props {}

export default function IntCluster(block: any): ReactElement {
  const { items } = block;

  return (
    <Container>
      <Grid>
        {items.map((item: any, i: number) => {
          return (
            <IntHighlightItem
              key={`int-highlight--${i}`}
              title={item.title}
              subTitle={item.description}
            />
          );
        })}
      </Grid>
    </Container>
  );
}
