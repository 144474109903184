import Grid from '@components/grid';
import Container from '@components/ui/container';
import Text from '@components/ui/text';
import { ASSET_URL } from '@lib/constants';
import Image from 'next/image';
import React from 'react';
import Divider from './Divider';

export default function ContentBlockTwo(block: any) {
  const { title, subTitle, description, bulletPoints, image, options } = block;
  const { lines = true } = options;

  return (
    <Container>
      <Divider />
      <Grid>
        <div className="col-span-full flex flex-col justify-start">
          <h5 className="font-terminal text-telesoft">{subTitle}</h5>
          <Text className="mb-4" variant="pageHeading">
            {title}
          </Text>
          <Text className="mb-4 max-w-xl" variant="body">
            {description}
          </Text>
        </div>
        <div className="relative col-span-full lg:col-span-6 min-h-[240px] lg:min-h-[400px]">
          {lines && (
            <>
              <div className="invisible lg:visible absolute inset-0 top-10 -left-10 -bottom-10 right-10 border border-black" />
              <div className="invisible lg:visible absolute inset-0 top-4 -left-4 -bottom-4 right-4 bg-white" />
            </>
          )}
          <Image
            src={
              image
                ? ASSET_URL + '/' + image.filename
                : '/assets/images/diagram-blue.png'
            }
            loading="lazy"
            alt={image?.alt || ''}
            layout="fill"
            className="object-contain object-center"
          />
        </div>
        <div className="col-span-full lg:col-span-6 flex flex-col justify-start items-end">
          <div className="flex flex-col justify-start items-start">
            <div className="mt-8 grid grid-cols-1 gap-4 gap-y-8">
              {bulletPoints.map((item: any, i: number) => {
                const includeSubtitle = item?.includeSubtitle ?? false;
                const imageUrl = ASSET_URL + '/' + item.image.filename;
                return (
                  <ListItem
                    key={`item--map--${i}`}
                    title={item.title}
                    isSubtitle={includeSubtitle}
                    subTitle={item?.subTitle}
                    imageUrl={imageUrl}
                  >
                    {item.description}
                  </ListItem>
                );
              })}
            </div>
          </div>
        </div>
      </Grid>
      <Divider />
    </Container>
  );
}

function ListItem({
  children,
  title,
  isSubtitle,
  subTitle,
  imageUrl,
}: {
  children: React.ReactNode;
  title: string;
  isSubtitle: boolean;
  subTitle: string;
  imageUrl: string;
}) {
  return (
    <div className="col-span-1 flex justify-start items-start">
      <div className="w-12 h-12 mt-1.5 mr-4 flex text-telesoft text-xl justify-center items-center border-b border-black border-t border-black">
        <div className="relative w-6 h-6">
          <Image
            src={imageUrl || '/assets/images/icon-2.svg'}
            loading="lazy"
            alt={''}
            width={24}
            height={24}
          />
        </div>
      </div>
      <div className="flex flex-col items-start">
        {isSubtitle && (
          <h5 className="font-terminal text-telesoft">{subTitle}</h5>
        )}
        <Text variant="heading" className="font-medium text-black">
          {title}
        </Text>
        <Text className="font-medium max-w-lg">{children}</Text>
      </div>
    </div>
  );
}
