import Container from '@components/ui/container';
import HeroWithBackgroundVideo from '@containers/hero-with-background-video';
import React, { ReactElement } from 'react';
import { ASSET_URL } from 'lib/constants';
import BannerCard from '@components/BannerCard';

function HeroSliderFullscreen(block: any): ReactElement {
  const { title, description, buttons, video, options } = block;
  const { terminal } = options;

  return (
    <HeroWithBackgroundVideo
      padding={false}
      source={
        video?.filename
          ? ASSET_URL + '/' + video.filename
          : '/assets/video/telesoft-home-hero-bg.mp4'
      }
    >
      <div className="z-30 relative overflow-hidden">
        <Container className="h-screen flex justify-center items-center">
          <BannerCard
            title={title}
            description={description}
            idx={0}
            buttons={buttons}
            terminal={terminal}
          />
        </Container>
      </div>
    </HeroWithBackgroundVideo>
  );
}

export default HeroSliderFullscreen;
