import type { FC } from 'react';

import VacancyCard from '@components/vacancy-card';
import Container from '@components/ui/container';

interface VacancyGridProps {
  className?: string;
  vacancies: any;
}

export const CareerOpenings: FC<VacancyGridProps> = ({
  className = '',
  vacancies,
}) => {
  return (
    <Container>
      {vacancies.length === 0 && (
        <div className="w-full bg-accent-1 flex justify-center items-center">
          <div className="flex flex-col justify-center items-start">
            <h4>
              No vacancies found. This list is regularly updated so please check
              again soon.
            </h4>
          </div>
        </div>
      )}
      <div
        className={`grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-x-3 lg:gap-x-5 xl:gap-x-7 gap-y-3 xl:gap-y-5 2xl:gap-y-8 ${className}`}
      >
        {vacancies.map((vacancy: any) => {
          return (
            <VacancyCard
              key={`vacancy--key${vacancy.id}`}
              vacancy={vacancy}
              variant="list"
            />
          );
        })}
      </div>
    </Container>
  );
};

export default CareerOpenings;
