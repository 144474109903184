import CMSLink from '@components/CMSLink';
import Grid from '@components/grid';
import Button from '@components/ui/button';
import Container from '@components/ui/container';
import Text from '@components/ui/text';
import { ASSET_URL } from '@lib/constants';
import Image from 'next/image';
import React from 'react';
import { IoCheckmark } from 'react-icons/io5';
import Divider from './Divider';

export default function ContentBlockOne(block: any) {
  const { title, description, options, image, bulletPoints, link } = block;
  const { split = false, lines = true } = options;
  const variant = options.variant || 'right';
  return (
    <Container>
      <Divider />
      <Grid>
        {!split && variant === 'left' && (
          <>
            <div className="col-span-full lg:col-span-6 flex flex-col justify-start items-start">
              <div className="flex flex-col justify-start items-start">
                <Text
                  className="mb-4 max-w-lg xl:max-w-xl"
                  variant="pageHeading"
                >
                  {title}
                </Text>
                <Text className="mb-4 max-w-lg xl:max-w-xl" variant="body">
                  {description}
                </Text>
                <div className="mt-8 grid grid-cols-2 gap-4 max-w-xl">
                  {bulletPoints.map((item: any, i: number) => {
                    return (
                      <ListItem key={`item--map--${i}`}>{item.item}</ListItem>
                    );
                  })}
                </div>
                <CMSLink {...link}>
                  <Button className="float-left mt-8" variant="cta">
                    {link.label || 'Learn More'}
                  </Button>
                </CMSLink>
              </div>
            </div>
            <div className="relative col-span-full lg:col-span-6 min-h-[240px] lg:min-h-[400px]">
              {lines && (
                <>
                  <div className="invisible lg:visible absolute inset-0 top-10 -left-10 -bottom-10 right-10 border border-telesoft" />
                  <div className="invisible lg:visible absolute inset-0 top-4 -left-4 -bottom-4 right-4 bg-white" />
                </>
              )}
              <Image
                src={
                  image
                    ? ASSET_URL + '/' + image.filename
                    : '/assets/images/telesoft-soc.png'
                }
                loading="lazy"
                alt={image?.alt || ''}
                layout="fill"
                className="object-cover object-center"
              />
            </div>
          </>
        )}
        {!split && variant === 'right' && (
          <>
            <div className="order-last lg:order-first relative col-span-full lg:col-span-6 min-h-[240px] lg:min-h-[400px]">
              {lines && (
                <>
                  <div className="invisible lg:visible absolute inset-0 top-10 -right-10 -bottom-10 left-10 border border-telesoft" />
                  <div className="invisible lg:visible absolute inset-0 top-4 -right-4 -bottom-4 left-4 bg-white" />
                </>
              )}
              <Image
                src={
                  image
                    ? ASSET_URL + '/' + image.filename
                    : '/assets/images/telesoft-soc.png'
                }
                loading="lazy"
                alt={image?.alt || ''}
                layout="fill"
                className="object-cover object-center"
              />
            </div>
            <div className="col-span-full lg:col-span-6 flex flex-col justify-start items-end">
              <div className="flex flex-col justify-start items-start">
                <Text
                  className="mb-4 max-w-lg xl:max-w-xl"
                  variant="pageHeading"
                >
                  {title}
                </Text>
                <Text className="mb-4 max-w-lg xl:max-w-xl" variant="body">
                  {description}
                </Text>
                <div className="mt-8 grid grid-cols-2 gap-4 max-w-xl">
                  {bulletPoints.map((item: any, i: number) => {
                    return (
                      <ListItem key={`item--map--${i}`}>{item.item}</ListItem>
                    );
                  })}
                </div>
                <CMSLink {...link}>
                  <Button className="float-left mt-8" variant="cta">
                    {link.label || 'Learn More'}
                  </Button>
                </CMSLink>
              </div>
            </div>
          </>
        )}

        {split && (
          <>
            <div className="col-span-full flex flex-col justify-start items-start">
              <div className="flex flex-col justify-start items-start">
                <Text className="mb-4 " variant="pageHeading">
                  {title}
                </Text>
                <Text className="mb-4 " variant="body">
                  {description}
                </Text>
                <div className="mt-8 grid grid-cols-2 gap-4 ">
                  {bulletPoints.map((item: any, i: number) => {
                    return (
                      <ListItem key={`item--map--${i}`}>{item.item}</ListItem>
                    );
                  })}
                </div>
                <CMSLink {...link}>
                  <Button className="float-left mt-8" variant="cta">
                    {link.label || 'Learn More'}
                  </Button>
                </CMSLink>
              </div>
            </div>

            <div className="relative col-span-full min-h-[240px] lg:min-h-[400px]">
              {lines && (
                <>
                  <div className="invisible lg:visible absolute inset-0 top-10 -left-10 -bottom-10 right-10 border border-telesoft" />
                  <div className="invisible lg:visible absolute inset-0 top-4 -left-4 -bottom-4 right-4 bg-white" />
                </>
              )}
              <Image
                src={
                  image
                    ? ASSET_URL + '/' + image.filename
                    : '/assets/images/telesoft-soc.png'
                }
                loading="lazy"
                alt={image?.alt || ''}
                layout="fill"
                className="object-cover object-center"
              />
            </div>
          </>
        )}
      </Grid>
      <Divider />
    </Container>
  );
}

export function ListItem({ children }: { children: React.ReactNode }) {
  return (
    <div className="col-span-full md:col-span-1 flex justify-start items-center">
      <div className="w-4 h-auto mr-3 border-b border-black border-t border-black">
        <IoCheckmark className="text-telesoft" />
      </div>
      <Text className="font-medium text-black">{children}</Text>
    </div>
  );
}
