import HeroBannerCard from '@components/common/hero-banner-card';
import React, { ReactElement } from 'react';
import Container from '@components/ui/container';

function TelesoftHeroMap(block: any): ReactElement {
  const { slides } = block;
  return (
    <>
      <div
        id="telesoft-city"
        className="min-h-screen bg-black w-full overflow-hidden relative"
      >
        <div className="absolute inset-0 overflow-hidden">
          <Container className="h-screen flex justify-center items-center">
            <HeroBannerCard
              idx={0}
              terminal={true}
              slide={slides[0]}
              href={`${slides[0].slug}`}
            />
          </Container>
        </div>
      </div>
    </>
  );
}

export default TelesoftHeroMap;
