import Text from '@components/ui/text';
import React, { ReactElement } from 'react';
import Image from 'next/image';
import Button from '@components/ui/button';
import Grid from '@components/grid';
import { VscChevronRight } from 'react-icons/vsc';
import { useInView } from 'react-intersection-observer';
import AnimatedLineContent from '@components/animated-line-content';
import { ASSET_URL } from 'lib/constants';
import Container from '@components/ui/container';
import CMSLink from '@components/CMSLink';
import { useUI } from '@contexts/ui.context';

export default function ProductHighlight(block: any): ReactElement {
  const { openModal, setModalView, setModalData } = useUI();
  const { title, description, image, link, options } = block;
  const isDownloadWalled: boolean = link?.resource?.isWalled ?? false;

  function handleDownload(resourceId: string, walled: boolean) {
    if (walled) {
      setModalView('DOWNLOAD_VIEW');
      setModalData({ resourceId: resourceId });
      return openModal();
    }
  }

  const {
    pulse,
    theme = 'dark',
    elementId,
    imageClassName = 'object-cover object-center opacity-25',
    className = theme === 'dark' ? 'bg-black' : 'bg-accent-1',
    overlayClassName = 'bg-gradient-to-t from-telesoft to-transparent mix-blend-overlay',
  } = options;

  const { ref, inView } = useInView({
    threshold: 0.4,
  });

  return (
    <Container>
      <Grid
        id={elementId}
        className={`relative lg:min-h-[500px] place-content-center ${className} p-8 md:p-12 lg:p-20`}
      >
        <div
          ref={ref}
          className="z-20 col-span-full lg:col-span-7  flex relative justify-center items-start"
        >
          <Text
            variant="subHeading"
            className={`${
              theme === 'dark' ? 'text-white' : 'text-black'
            } text-left max-w-md leading-relaxed"`}
          >
            {title}
          </Text>
        </div>
        <div className="z-20 col-span-full lg:col-span-5 flex relative justify-start items-start">
          <div className="flex flex-col justify-start items-start max-w-md">
            {inView && (
              <AnimatedLineContent
                variant={theme === 'dark' ? 'light' : 'dark'}
              />
            )}
            <Text
              className={`pt-6 ${
                theme === 'dark' ? 'text-white' : 'text-black'
              }`}
            >
              {description}
            </Text>

            <CMSLink {...link}>
              <Button
                variant="cta"
                invert={theme === 'dark'}
                onClick={
                  link.type === 'resource'
                    ? () => handleDownload(link.resource.id, isDownloadWalled)
                    : undefined
                }
                className={`mt-10`}
              >
                <VscChevronRight className="mr-1" />
                {link.label}
              </Button>
            </CMSLink>
          </div>
        </div>

        <div className={`z-10 absolute inset-0 ${overlayClassName}`} />

        <div className="absolute inset-0 z-0">
          <Image
            layout="fill"
            loading="lazy"
            alt={image.alt || ''}
            className={`${imageClassName} ${
              pulse && 'object-contain animate-pulse'
            }`}
            src={ASSET_URL + '/' + image.filename}
          />
        </div>
      </Grid>
    </Container>
  );
}
