import React, { ReactElement, useEffect } from 'react';

export default function HeroLines(): ReactElement {
  function mouseWatch(e: any) {
    const sqrs = document.querySelectorAll('.item');

    const mouseX = e.pageX;
    const mouseY = e.pageY;

    sqrs.forEach((sqr) => {
      //@ts-ignore
      const sqrX = sqr.offsetLeft + 20;
      //@ts-ignore
      const sqrY = sqr.offsetTop + 20;

      const diffX = mouseX - sqrX;
      const diffY = mouseY - sqrY;

      const radians = Math.atan2(diffY, diffX);

      const angle = (radians * 180) / Math.PI;
      //@ts-ignore
      sqr.style.transform = `rotate(${angle}deg)`;
    });
  }

  useEffect(() => {
    document.addEventListener('mousemove', mouseWatch);
    return () => {
      document.removeEventListener('mousemove', mouseWatch);
    };
  }, []);

  return (
    <div className="w-full z-10 flex justify-center items-center">
      <div className="wrapper">
        <div className="item"></div>
        <div className="item"></div>
        <div className="item"></div>

        <div className="item"></div>
        <div className="item"></div>
        <div className="item"></div>

        <div className="item"></div>
        <div className="item"></div>
        <div className="item"></div>

        <div className="item"></div>
        <div className="item"></div>
        <div className="item"></div>

        <div className="item"></div>
        <div className="item"></div>
        <div className="item"></div>

        <div className="item"></div>
        <div className="item"></div>
        <div className="item"></div>
      </div>
    </div>
  );
}
