import dynamic from 'next/dynamic';

import VideoHighlight from '@blocks/Videohighlight';
import HeroBackgroundImage from '@blocks/HeroBackgroundImage';
import ButtonsOverlayImageHero from './ButtonsOverlayImageHero';
import CareerOpenings from './CareerOpenings';
import ContactForm from './ContactForm';
import ContentBlockOne from './ContentBlockOne';
import ContentBlockTwo from './ContentBlockTwo';
// const ContentImage = dynamic(() => import("./ContentImage"))
import ContentImage from './ContentImage';
const ContrastHeritageGrid = dynamic(() => import('./ContrastHeritageGrid'));
const DarkResourcesGallery = dynamic(() => import('./DarkResourcesGallery'));
const DemoCTA = dynamic(() => import('./DemoCTA'));
const DemonstrationForm = dynamic(() => import('./DemonstrationForm'));
// const Divider = dynamic(() => import("./Divider"));
import Divider from './Divider';
import FullWidthImage from './FullWidthImage';
import HeroBackgroundVideo from './HeroBackgroundVideo';
const DualColumnFeaturesGrid = dynamic(
  () => import('./DualColumnFeaturesGrid')
);
const DualColumnInfo = dynamic(() => import('./DualColumnInfo'));
const FAQTooltips = dynamic(() => import('./FAQTooltips'));
const FeatureLinkGrid = dynamic(() => import('./FeatureLinkGrid'));
const GenericCTA = dynamic(() => import('./GenericCTA'));
import HeroSliderFullscreen from './HeroSliderFullscreen';
// const InfoCalloutImage = dynamic(() => import("./InfoCalloutImage"))
import InfoCalloutImage from './InfoCalloutImage';
const InfoColumnImage = dynamic(() => import('./InfoColumnImage'));
const InfoVideoCTAButton = dynamic(() => import('./InfoVideoCTAButton'));
import CenterBulletPoints from './CenterBulletPoints';
// const IntCluster = dynamic(() => import("./IntCluster"))
import IntCluster from './IntCluster';
const LargeHeroImage = dynamic(() => import('./LargeHeroImage'));
const LightTabbedFeatures = dynamic(() => import('./LightTabbedFeatures'));
const MessageDarkHighlight = dynamic(() => import('./MessageDarkHighlight'));
const OfficesList = dynamic(() => import('./OfficesList'));
const PairImageCallout = dynamic(() => import('./PairImageCallout'));
const PartialHeader = dynamic(() => import('./PartialHeader'));
const PartnerLogoSlider = dynamic(() => import('./PartnerLogoSlider'), {
  ssr: false,
});
// import PartnerLogoSlider from './PartnerLogoSlider'
// const ProductHighlight = dynamic(() => import("./ProductHighlight"))
import ProductHighlight from './ProductHighlight';
import QuickLinksCallout from './QuickLinksCallout';
const RicherText = dynamic(() => import('./RicherText'));
const SnippetsInfoImage = dynamic(() => import('./SnippetsInfoImage'));
const SolutionsCalloutText = dynamic(() => import('./SolutionsCalloutText'));
const SplitCTA = dynamic(() => import('./SplitCTA'));
const SplitDownloadCTA = dynamic(() => import('./SplitDownloadCTA'));
const SplitInfoDiagram = dynamic(() => import('./SplitInfoDiagram'));
const StandaloneHeaderCluster = dynamic(
  () => import('./StandaloneHeaderCluster')
);
import SuperHeadingHero from './SuperHeadingHero';
const SupportForm = dynamic(() => import('./SupportForm'));
import LeadCapture from '@components/LeadCapture';
const SupportPlansGrid = dynamic(() => import('./SupportPlansGrid'));
// const TabbedDiagram = dynamic(() => import("./TabbedDiagram"))
import TabbedDiagram from './TabbedDiagram';
import Form from './Form';
const TrioLinkCTA = dynamic(() => import('./TrioLinkCTA'));
const WatchVideoCTA = dynamic(() => import('./WatchVideoCTA'));
const FeaturedProductList = dynamic(() => import('./FeaturedProductList'));
const FeaturedSolutionList = dynamic(() => import('./FeaturedSolutionList'));
const LineList = dynamic(() => import('./LineList'));
const MapFullWidth = dynamic(() => import('./MapFullWidth'));
const SubscribeToNucleusCTA = dynamic(() => import('./SubscribeToNucleusCTA'));
import ContactHeroMap from './ContactHeroMap';
import DemonstrationFormHero from './DemonstrationFormHero';
// const TelesoftHeroMap = dynamic(() => import("./TelesoftHeroMap"))
import TelesoftHeroMap from './TelesoftHeroMap';
const TelesoftHeroCity = dynamic(() => import('./TelesoftHeroCity'));
const CenterContent = dynamic(() => import('@blocks/CenterContent'));
const TitleDarkHighlight = dynamic(() => import('./TitleDarkHighlight'));
// const DarkFooterCTA = dynamic(() => import("./DarkFooterCTA"))
const FullWidthDynamicBlock = dynamic(() => import('./FullWidthDynamicBlock'));
import DarkFooterCTA from './DarkFooterCTA';
import TypeformEmbed from './TypeformEmbed';
const DescriptionImage = dynamic(() => import('./DescriptionImage'));
const ProductLifecycle = dynamic(() => import('./ProductLifecycle'));
const DiagramCluster = dynamic(() => import('./DiagramCluster'));
const EnterpriseDiagram = dynamic(() => import('./EnterpriseDiagram'));
const BenefitsSlider = dynamic(() => import('./BenefitsSlider'));

export const components = {
  'hero-slider-fullscreen': HeroSliderFullscreen,
  'telesoft-hero-map': TelesoftHeroMap,
  'hero-background-video': HeroBackgroundVideo,
  'telesoft-hero-city': TelesoftHeroCity,
  'partner-logo-slider': PartnerLogoSlider,
  'info-callout-image': InfoCalloutImage,
  'content-block-one': ContentBlockOne,
  'content-block-two': ContentBlockTwo,
  'content-image': ContentImage,
  'center-content': CenterContent,
  'int-cluster': IntCluster,
  'trio-link-cta': TrioLinkCTA,
  'tabbed-diagram': TabbedDiagram,
  'feature-link-grid': FeatureLinkGrid,
  'product-highlight': ProductHighlight,
  'product-lifecycle': ProductLifecycle,
  'pair-image-callout': PairImageCallout,
  'demo-cta': DemoCTA,
  'description-image': DescriptionImage,
  'dark-footer-cta': DarkFooterCTA,
  'watch-video-cta': WatchVideoCTA,
  'super-heading-hero': SuperHeadingHero,
  'offices-list': OfficesList,
  'contact-form': ContactForm,
  'generic-cta': GenericCTA,
  'demonstration-form': DemonstrationForm,
  'lead-capture': LeadCapture,
  'large-hero-image': LargeHeroImage,
  'quick-links-callout': QuickLinksCallout,
  'dual-column-info': DualColumnInfo,
  'light-tabbed-features': LightTabbedFeatures,
  'partial-header': PartialHeader,
  'dual-column-features-grid': DualColumnFeaturesGrid,
  'dark-resources-gallery': DarkResourcesGallery,
  'split-info-diagram': SplitInfoDiagram,
  'snippets-info-image': SnippetsInfoImage,
  'contrast-heritage-grid': ContrastHeritageGrid,
  'video-highlight': VideoHighlight,
  'split-download-cta': SplitDownloadCTA,
  'split-cta': SplitCTA,
  'hero-background-image': HeroBackgroundImage,
  'featured-product-list': FeaturedProductList,
  'featured-solution-list': FeaturedSolutionList,
  'line-list': LineList,
  'map-full-width': MapFullWidth,
  'info-column-image': InfoColumnImage,
  divider: Divider,
  'full-width-dynamic-block': FullWidthDynamicBlock,
  'subscribe-to-nucleus-cta': SubscribeToNucleusCTA,
  'buttons-overlay-image-hero': ButtonsOverlayImageHero,
  'support-form': SupportForm,
  'support-plans-grid': SupportPlansGrid,
  'career-openings': CareerOpenings,
  'standalone-header-cluster': StandaloneHeaderCluster,
  'solutions-callout-text': SolutionsCalloutText,
  'info-video-cta-button': InfoVideoCTAButton,
  'faq-tooltips': FAQTooltips,
  'message-dark-highlight': MessageDarkHighlight,
  'title-dark-highlight': TitleDarkHighlight,
  'richer-text': RicherText,
  'contact-hero-map': ContactHeroMap,
  'demonstration-form-hero': DemonstrationFormHero,
  'diagram-cluster': DiagramCluster,
  'enterprise-diagram': EnterpriseDiagram,
  'benefits-slider': BenefitsSlider,
  'full-width-image': FullWidthImage,
  'center-bullet-points': CenterBulletPoints,
  form: Form,
  'typeform-embed': TypeformEmbed,
};
