import React, { ReactElement } from 'react';
import { IoMdReturnRight } from 'react-icons/io';
import Text from './ui/text';
import CMSLink from './CMSLink';

export type LinkType = {
  type: 'page' | 'custom';
  label: string;
  page?: any;
  url?: string;
};

interface Props {
  link: LinkType;
}

function ProductLinkItem({ link }: Props): ReactElement {
  return (
    <CMSLink {...link}>
      <div className="group cursor-pointer flex justify-start items-center">
        <IoMdReturnRight className="mr-2 md:mr-4 text-sm md:text-base lg:text-xl" />
        <Text variant="heading" className="group-hover:underline">
          {link.label}
        </Text>
      </div>
    </CMSLink>
  );
}

export default ProductLinkItem;
