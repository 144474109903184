import { useFormspark } from '@formspark/use-formspark';
import { RECAPTACHA_SITE_KEY } from '@lib/constants';
import { onSubmitForm } from '@lib/onSubmitForm';
import { formStore } from '@store/form-store';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useSnapshot } from 'valtio';
import TermsCluster from './common/modal/terms-cluster';
import BrowserSelect from './ui/browser-select';
import FormLoadingOverlay from './ui/form-loading-overlay';
import FormSubmitButton from './ui/form-submit-button';
import Input from './ui/input';
import SubmittedState from './ui/submitted-state';

export type TField = {
  isRequired: boolean;
  errorMessage?: string;
  label: string;
  name: string;
  type: 'text' | 'textarea' | 'email' | 'radio' | 'number' | 'tel' | 'url';
  options: [
    {
      label: string;
      value: string;
    }
  ];
};

export type TForm = {
  fields: [{ field: TField }];
  name: string;
  buttonText: string | null;
  options: {
    buttonText?: string;
    id?: string;
    templateId?: string;
  };
};

const FORMSPARK_FORM_ID = 'cPBfTyEf';

export default function FormComponent({
  form,
  className,
}: {
  form: TForm;
  className?: string;
}) {
  const [submit, _] = useFormspark({ formId: FORMSPARK_FORM_ID });
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const snap = useSnapshot(formStore);
  const isLoading = snap.isLoading;
  const reRef = React.useRef<any>(null);
  const defClassName = `lg:row-span-2 col-span-full min-h-[800px] w-full lg:col-span-5 bg-white p-8 md:p-10 lg:p-14 flex flex-col grow justify-center`;

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  if (
    snap.fields.firstName &&
    snap.fields.lastName &&
    snap.fields.email &&
    snap.fields.phone
  ) {
    setValue('firstName', snap.fields.firstName);
    setValue('lastName', snap.fields.lastName);
    setValue('email', snap.fields.email);
    setValue('phone', snap.fields.phone);
  }

  if (snap.fields.company) {
    setValue('company', snap.fields.company);
  }

  function isValidMailAddress(email: string) {
    let forbiddenDomains = [
      'outlook.com',
      'outlook.co.uk',
      'yahoo.co.uk',
      'yahoo.com',
      'hotmail.com',
      'hotmail.co.uk',
      'sky.com',
      'icloud.com',
      'mac.com',
      'gmail.com',
      'proton.me',
      'pm.me',
    ];
    if (forbiddenDomains.some((v: string) => email.toLowerCase().includes(v))) {
      return false;
    }
    return true;
  }

  async function onSubmit(fields: any) {
    onSubmitForm(
      form.name,
      reRef,
      fields,
      'form-submissions',
      setIsSubmitted,
      reset
    );
    submit(fields);
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={className ? className : defClassName}
      noValidate
    >
      <ReCAPTCHA
        size="invisible"
        ref={reRef}
        sitekey={
          RECAPTACHA_SITE_KEY || '6Ldjcd8ZAAAAAACdcnBVXEIjMiv6z4D-NbarDmtE'
        }
      />
      {isSubmitted && (
        <SubmittedState className="h-full flex flex-col justify-center items-center" />
      )}
      {!isSubmitted && (
        <div className="flex flex-col space-y-4">
          <fieldset className="relative space-y-4 mb-4">
            <FormLoadingOverlay show={isLoading} />
            {form?.fields &&
              form.fields.length > 0 &&
              form.fields.map(({ field }: { field: TField }, i: number) => {
                const { name, label, isRequired, errorMessage, type } = field;
                let errObj = errors;
                let errKey = name;
                let errMsg;
                ({ [errKey]: errMsg } = errObj);
                if (field.type === 'radio' && field?.options) {
                  return (
                    <BrowserSelect
                      key={`field--${i}`}
                      options={field.options}
                      labelKey={label}
                      {...register(name)}
                      errorKey={errors && errMsg?.message}
                    />
                  );
                }
                return (
                  <Input
                    key={`field--map--${i}`}
                    labelKey={isRequired ? `${label}*` : label}
                    type={type}
                    variant="solid"
                    {...register(name, {
                      required: isRequired ? errorMessage : undefined,
                      validate: {
                        workEmail: (v) => {
                          if (name !== 'email') {
                            return true;
                          }
                          const isValidEmail = isValidMailAddress(v);
                          if (!isValidEmail) {
                            return 'Please use a work email';
                          }
                          return true;
                        },
                      },
                      pattern:
                        name === 'email'
                          ? {
                              value:
                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: 'Please use a valid email',
                            }
                          : undefined,
                    })}
                    errorKey={errors && errMsg?.message}
                  />
                );
              })}
          </fieldset>
          <FormSubmitButton
            buttonText={form?.options?.buttonText}
            isLoading={isLoading}
          />
          <TermsCluster />
        </div>
      )}
    </form>
  );
}
