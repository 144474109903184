import React, { ReactElement } from 'react';
import Grid from '@components/grid';
import Container from '@components/ui/container';
import Divider from './Divider';
import FormComponent from '@components/FormComponent';

export default function ContactForm(block: any): ReactElement {
  const { form } = block;

  return (
    <>
      <Divider />
      <Container>
        <Grid>
          <div className="col-span-full">
            <FormComponent
              form={form}
              className={'bg-gray-200 py-8 px-8 lg:px-12'}
            />
          </div>
        </Grid>
      </Container>
    </>
  );
}
