import type { FC } from 'react';
import cn from 'classnames';
import Link from 'next/link';

import { BsChevronRight } from 'react-icons/bs';
import Text from './ui/text';
import { Career } from '@payload/types';

interface VacancyProps {
  vacancy: Career;
  className?: string;
  contactClassName?: string;
  imageContentClassName?: string;
  variant?: 'grid' | 'gridSlim' | 'list' | 'listSmall';
  imgWidth?: number | string;
  imgHeight?: number | string;
  imgLoading?: 'eager' | 'lazy';
}

const VacancyCard: FC<VacancyProps> = ({
  vacancy,
  className = '',
  contactClassName = '',
  variant = 'list',
}) => {
  return (
    <Link passHref href={`/vacancy/${vacancy.slug}`}>
      <button
        className={cn(
          'group box-border flex cursor-pointer h-full border-b text-heading border-heading ',
          {
            'pe-0 flex-col w-full items-start justify-items-stretch bg-white transition duration-200 ease-in-out':
              variant === 'grid',
            'pe-0 flex-col items-start bg-white': variant === 'gridSlim',
            'items-start bg-transparent border border-gray-100 transition duration-200 ease-in-out transform':
              variant === 'listSmall',
            'flex-row items-start transition-transform ease-linear hover:border-telesoft py-8 pe-2 lg:pe-3 2xl:pe-4':
              variant === 'list',
          },
          className
        )}
        title={vacancy?.title}
      >
        <div
          className={cn(
            'w-full mb-4',
            {
              'ps-0 transition-all duration-300 pe-2.5 xl:pe-4':
                variant === 'grid',
              'ps-0': variant === 'gridSlim',
              'px-4 lg:px-5 2xl:px-4': variant === 'listSmall',
            },
            contactClassName
          )}
        >
          <Text variant="heading" className="text-left mb-4">
            {vacancy?.title}
          </Text>
          {vacancy?.summary && (
            <Text className="text-body text-left text-xs lg:text-sm leading-normal max-w-[90%] xl:leading-relaxed">
              {vacancy?.summary && vacancy.summary.length > 128
                ? vacancy.summary.substring(0, 128) + '...'
                : vacancy.summary}
            </Text>
          )}
        </div>

        <div className="flex items-center">
          <BsChevronRight
            size="18"
            className="block xl:block text-sm text-heading transition duration-300 group-hover:translate-x-2"
          />
        </div>
      </button>
    </Link>
  );
};

export default VacancyCard;
