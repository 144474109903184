import { motion, AnimatePresence } from 'framer-motion';

export default function FormLoadingOverlay({ show }: { show: boolean }) {
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="absolute z-30 animate-pulse inset-0 bg-white bg-opacity-80"
        ></motion.div>
      )}
    </AnimatePresence>
  );
}
