import React from 'react';
import Text from '../components/ui/text';
import Container from '../components/ui/container';
import { IoMdReturnRight } from 'react-icons/io';
import { ASSET_URL } from '@lib/constants';

export default function ContactHeroMap(block: any) {
  const { title, image } = block;

  return (
    <div
      className="flex relative flex-col justify-end bg-heading min-h-[500px] bg-cover bg-center"
      style={{ backgroundImage: `url(${ASSET_URL}/${image.filename})` }}
    >
      <Container className="w-full z-20 mb-8 select-none">
        <div className="col-span-full md:col-span-4 lg:col-span-6 xl:col-span-7 flex justify-start items-center">
          <IoMdReturnRight className="mr-1 text-white text-xl" />
          <Text variant="superHeading" className="text-white">
            {title || 'Contact'}
          </Text>
        </div>
      </Container>
    </div>
  );
}
