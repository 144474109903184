import React from 'react';
import { components } from '@blocks/index';

type Props = {
  blocks: any;
  className?: string;
};

const RenderBlocks: React.FC<Props> = ({ blocks, className }) => (
  <div className={[className].filter(Boolean).join(' ')}>
    {blocks.map((block: any, i: number) => {
      //@ts-ignore
      const Block: React.FC<any> = components[block.blockType];

      if (Block) {
        return (
          <section key={`block--${i}`}>
            <Block {...block} />
          </section>
        );
      }

      return null;
    })}
  </div>
);

export default RenderBlocks;
