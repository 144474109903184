import Grid from '@components/grid';
import Text from '@components/ui/text';
import React, { ReactElement } from 'react';
import Image from 'next/image';
import ImageButtonWithLabel from '@components/image-button-with-label';
import Button from '@components/ui/button';
import { ASSET_URL } from 'lib/constants';
import Container from '@components/ui/container';
import CMSLink from '@components/CMSLink';

export const textWrapperClassName =
  'col-span-full md:col-span-4 lg:col-span-6 p-8 md:p-12 lg:p-20 bg-accent-1 flex flex-col justify-center items-start';
export const imageWrapperClassName =
  'col-span-full md:col-span-4 lg:col-span-6 relative bg-black';

export default function InfoCalloutImage(block: any): ReactElement {
  const { title, description, image } = block;

  const {
    isButton = true,
    link,
    variant = 'left',
    className,
    order = '1',
    terminal = false,
    overlayClassName = 'bg-gradient-to-r from-telesoft to-transparent mix-blend-soft-light',
    overlayButton,
    scale,
  } = block.options;

  console.log(link);

  return (
    <Container>
      {variant === 'left' ? (
        <>
          <Grid
            className={`group ${className} grid-rows-[128px_minmax(auto,_1fr)] md:grid-rows-1`}
          >
            <div className={`${textWrapperClassName}`}>
              <div className="flex flex-col place-items-start">
                {terminal && (
                  <Text className="text-sm font-light mb-1 font-terminal">
                    0{order}/
                  </Text>
                )}

                <div className="flex justify-start items-center">
                  <Text
                    variant="subHeading"
                    className={`text-left mb-3 text-black`}
                  >
                    {title}
                  </Text>
                </div>

                <Text className="text-left tracking-wide whitespace-pre-line">
                  {description}
                </Text>

                {isButton && (
                  <div className="flex justify-start items-center mt-8">
                    <CMSLink {...link}>
                      <Button variant="cta" className="mr-1">
                        {link.label ? link.label : 'Learn More'}
                      </Button>
                    </CMSLink>
                  </div>
                )}
              </div>
            </div>

            <div
              className={`${imageWrapperClassName} order-first h-40 md:h-auto md:order-none`}
            >
              <div className={`absolute inset-0 z-10 ${overlayClassName}`} />
              <Image
                src={
                  image
                    ? ASSET_URL + '/' + image.filename
                    : '/assets/images/telesoft-sales-6.jpeg'
                }
                alt={image?.alt || ''}
                className={`object-cover object-left lg:object-center ${
                  scale &&
                  'group-hover:scale-105 transition-transform duration-1000'
                }`}
                loading="lazy"
                // width="100%"
                // height="100%"
                layout="fill"
              />
              {overlayButton && (
                <ImageButtonWithLabel
                  label={title}
                  link={link}
                  variant="right"
                />
              )}
            </div>
          </Grid>
        </>
      ) : (
        <>
          <Grid className={`group ${className} grid-rows-2 lg:grid-rows-1`}>
            <div className={imageWrapperClassName}>
              <div className={`absolute inset-0 z-10 ${overlayClassName}`} />
              <Image
                src={
                  image
                    ? ASSET_URL + '/' + image.filename
                    : '/assets/images/telesoft-sales-6.jpeg'
                }
                alt={image?.alt || ''}
                className={`object-cover object-center ${
                  scale &&
                  'group-hover:scale-105 transition-transform duration-1000'
                }`}
                layout="fill"
                loading="lazy"
              />
              {overlayButton && (
                <ImageButtonWithLabel
                  label={title}
                  link={link}
                  variant="left"
                />
              )}
            </div>
            <div className={`${textWrapperClassName}`}>
              <div className="flex flex-col place-items-start">
                {terminal && (
                  <Text className="text-sm font-light mb-1 font-terminal">
                    0{order}/
                  </Text>
                )}
                <Text
                  variant="subHeading"
                  className={`text-left mb-3 text-black`}
                >
                  {title}
                </Text>

                <Text className="text-left tracking-wide">{description}</Text>

                {isButton && (
                  <div className="flex justify-start items-center mt-10">
                    <CMSLink {...link}>
                      <Button variant="cta" className="mr-1">
                        {link.label ? link.label : 'Learn More'}
                      </Button>
                    </CMSLink>
                  </div>
                )}
              </div>
            </div>
          </Grid>
        </>
      )}
    </Container>
  );
}
