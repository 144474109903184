import React, { ReactElement } from 'react';
import Image from 'next/image';
import { BsChevronRight } from 'react-icons/bs';
import dynamic from 'next/dynamic';

import Text from '@components/ui/text';
import Grid from '@components/grid';
import VideoModal from '@components/common/modal/video-modal';
import { ASSET_URL } from 'lib/constants';
import Container from '@components/ui/container';

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });

export default function VideoHighlight(block: any): ReactElement {
  const [showModal, setShowModal] = React.useState(false);
  const {
    title,
    subTitle,
    url = 'https://www.youtube.com/embed/sjkiw7OYbVY',
    options,
  } = block;
  const { variant = 'popup', image } = options;
  return (
    <>
      {variant === 'popup' && (
        <VideoModal
          showModal={showModal}
          setShowModal={setShowModal}
          title={title || 'TDAC at scale'}
          url={url}
        />
      )}
      <Container>
        <Grid className="gap-0 md:gap-8">
          <div className="col-span-full md:col-span-4 lg:col-span-6">
            <Text className="mt-2" variant="subHeading">
              {title}
            </Text>
          </div>
          <Text
            className="col-span-full  md:col-span-4 lg:col-span-6 mt-2"
            variant="heading"
          >
            {subTitle}
          </Text>
          {variant === 'inline' && (
            <div className="col-span-full relative pt-[56.25%]">
              <ReactPlayer
                title={title}
                url={url}
                className="absolute inset-0"
                width={'100%'}
                height={'100%'}
              />
            </div>
          )}
          {variant === 'popup' && (
            <button
              type="button"
              onClick={() => setShowModal(true)}
              className="group relative col-span-full mt-8 md:mt-0 overflow-hidden"
            >
              <Image
                src={
                  image?.filename
                    ? ASSET_URL + '/' + image.filename
                    : '/assets/images/telesoft-abstract-40.jpeg'
                }
                loading="lazy"
                alt={image?.alt || ''}
                className="w-full h-full object-cover object-center group-hover:scale-105 transition-transform duration-1000"
                width={1920}
                height={900}
              />
              <div className="absolute group-hover:opacity-80 flex justify-end items-center bottom-5 left-4">
                <div
                  className={`w-14 h-14 flex justify-center group-hover:border-telesoft items-center bg-accent-1 col-span-1 col-start-9 group-hover:bg-telesoft text-heading z-20 transition duration-300`}
                >
                  <BsChevronRight className="" />
                </div>
                <div className="w-20 h-[1px] bg-white mx-5" />

                <Text
                  variant="headingLight"
                  className="whitespace-nowrap font-medium text-white"
                >
                  Play Video
                </Text>
              </div>
            </button>
          )}
        </Grid>
      </Container>
    </>
  );
}
