import React, { Fragment } from 'react';
import escapeHTML from 'escape-html';
import { Text as SlateText } from 'slate';
import Text from '@components/ui/text';

const serialise = (children: any): React.ReactElement[] =>
  children.map((node: any, i: number) => {
    if (SlateText.isText(node)) {
      let text = (
        <span dangerouslySetInnerHTML={{ __html: escapeHTML(node.text) }} />
      );

      const textLen = node.text.length;

      if (textLen === 0) {
        text = <br />;
      }

      //@ts-ignore
      if (node.bold) {
        text = <strong key={i}>{text}</strong>;
      }
      //@ts-ignore
      if (node.code) {
        text = (
          <code className="p-8 bg-accent-1" key={i}>
            {text}
          </code>
        );
      }
      //@ts-ignore
      if (node.italic) {
        text = <em key={i}>{text}</em>;
      }
      //@ts-ignore
      if (node.underline) {
        text = (
          <span style={{ textDecoration: 'underline' }} key={i}>
            {text}
          </span>
        );
      }
      //@ts-ignore
      if (node.strikethrough) {
        text = (
          <span style={{ textDecoration: 'line-through' }} key={i}>
            {text}
          </span>
        );
      }

      return <Fragment key={i}>{text}</Fragment>;
    }

    if (!node) {
      return null;
    }

    switch (node.type) {
      case 'h1':
        return (
          <Text key={i} variant="subHeading">
            <br />
            {serialise(node.children)}
            <br />
          </Text>
        );
      case 'h2':
        return (
          <Text key={i} variant="mediumHeading">
            <br />
            {serialise(node.children)}
            <br />
          </Text>
        );
      case 'h3':
        return (
          <Text key={i} variant="heading" className="mb-2">
            <br />
            {serialise(node.children)}
            <br />
          </Text>
        );
      case 'h4':
        return (
          <Text key={i} variant="heading">
            {serialise(node.children)}
          </Text>
        );
      case 'h5':
        return <h5 key={i}>{serialise(node.children)}</h5>;
      case 'h6':
        return <h6 key={i}>{serialise(node.children)}</h6>;
      case 'quote':
        return <blockquote key={i}>{serialise(node.children)}</blockquote>;
      case 'ul':
        return <ul key={i}>{serialise(node.children)}</ul>;
      case 'ol':
        return <ol key={i}>{serialise(node.children)}</ol>;
      case 'li':
        return (
          <div className="pl-10">
            <li
              key={i}
              className={'text-left'}
              style={{ listStyleType: 'square', color: 'inherit' }}
            >
              <span>{serialise(node.children)}</span>
            </li>
          </div>
        );
      case 'link':
        return (
          <a
            className="border-b border-gray-300 hover:border-telesoft"
            href={escapeHTML(node.url)}
            rel="noreferrer"
            key={i}
            target="_blank"
          >
            {serialise(node.children)}
          </a>
        );

      default:
        return (
          <p key={i} className="whitespace-pre-line">
            {serialise(node.children)}
          </p>
        );
    }
  });

export default serialise;
