import React from 'react';
import Grid from '@components/grid';
import Container from '@components/ui/container';
import Text from '@components/ui/text';
import Button from '@components/ui/button';
import CMSLink from '@components/CMSLink';
import cn from 'classnames';
import { ListItem } from './ContentBlockOne';

function CenterBulletPoints(block: any) {
  const { title, subTitle, items, options } = block;
  const { link, hasButton } = options;

  return (
    <Container>
      <Grid>
        <div
          className={
            'col-span-full p-8 md:p-12 lg:p-20 bg-accent-1 flex flex-col justify-center items-start'
          }
        >
          <div className={'mx-auto flex flex-col justify-center items-start'}>
            {title && (
              <Text
                variant="mediumHeading"
                className={cn('max-w-4xl w-full mb-4', {
                  'text-center': options?.variant === 'center',
                  'text-left': options?.variant === 'left',
                })}
              >
                {title}
              </Text>
            )}
            {subTitle && (
              <Text
                variant="heading"
                className={cn('max-w-4xl w-full mb-4', {
                  'text-center': options?.variant === 'center',
                  'text-left': options?.variant === 'left',
                })}
              >
                {subTitle}
              </Text>
            )}

            <div className="mt-4 md:mt-8 grid grid-cols-1 gap-4 max-w-4xl space-y-2">
              {items?.map((item: any, i: number) => {
                return <ListItem key={`item--map--${i}`}>{item.item}</ListItem>;
              })}
            </div>

            {hasButton && (
              <div
                className={cn('w-full flex', {
                  'justify-center': options?.variant === 'center',
                  'justify-start': options?.variant === 'left',
                })}
              >
                <CMSLink {...link}>
                  <Button variant={'cta'} className={'mt-4 md:mt-6 lg:mt-8'}>
                    {link?.label ? link.label : 'LEARN MORE'}
                  </Button>
                </CMSLink>
              </div>
            )}
          </div>
        </div>
      </Grid>
    </Container>
  );
}

export default CenterBulletPoints;
