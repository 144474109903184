import { useInView } from 'react-intersection-observer';
import React, { ReactElement } from 'react';
import AnimatedLineContent from './animated-line-content';
import Text from './ui/text';
import { motion } from 'framer-motion';

interface Props {
  title: string;
  subTitle: string;
}

export default function IntHighlightItem({
  title,
  subTitle,
}: Props): ReactElement {
  const { ref, inView } = useInView({
    threshold: 0.4,
  });

  const staggerVariant = {
    initial: {
      opacity: 0,
    },
    animate: {
      transition: {
        staggerChildren: 1,
      },
    },
  };

  return (
    <motion.div
      variants={staggerVariant}
      initial={false}
      animate="animate"
      ref={ref}
      className="col-span-4 py-4 relative"
    >
      {inView && <AnimatedLineContent variant="dark" />}
      <div className="flex flex-col justify-start items-center">
        <div className="flex flex-col justify-start items-center">
          <Text variant="subHeading">{title}</Text>
          <h5 className="mt-4 opacity-50">{subTitle}</h5>
        </div>
      </div>
    </motion.div>
  );
}
