import React, { ReactElement } from 'react';
import Text from './ui/text';
import { Variant } from '@components/ui/text';
import { IoMdReturnRight } from 'react-icons/io';

interface Props {
  title?: string;
  content: string;
  className?: string;
  textClassName?: string;
  textVariant?: Variant;
}

export default function ContainedTextBlock({
  title,
  content,
  className,
  textClassName,
  textVariant,
}: Props): ReactElement {
  return (
    <div
      className={`col-span-full sm:col-span-2 md:col-span-4 border-t border-black lg:col-span-3 pt-4 mt-8 sm:mt-0 flex flex-col justify-start text-heading items-start ${className}`}
    >
      {title && (
        <div className="flex justify-start items-start">
          <IoMdReturnRight className="mr-2 text-lg mt-0.5 xl:mt-[5px] text-black" />
          <Text
            variant={'heading'}
            className={'mb-2 xl:mb-3 w-full text-black'}
          >
            {title}
          </Text>
        </div>
      )}

      <Text variant={textVariant || 'body'} className={textClassName}>
        {content}
      </Text>
    </div>
  );
}
