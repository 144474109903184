import Container from '@components/ui/container';
import Text from '@components/ui/text';
import { IoMdReturnRight } from 'react-icons/io';
import { ASSET_URL } from 'lib/constants';
import Button from '@components/ui/button';
import cn from 'classnames';
import CMSLink from '@components/CMSLink';

export default function HeroBackgroundImage(block: any) {
  const { title, description = false, image, options } = block;
  const {
    hasIcon,
    overlayClassName = 'opacity-80',
    textColor,
    buttons,
  } = options;
  const isButton = buttons?.length > 0 ?? false;
  const titleLen = title?.length;

  return (
    <div
      className={cn(
        'flex min-h-[56vh] bg-black justify-start items-end relative bg-no-repeat bg-center bg-cover',
        {
          'min-h-[64vh]': titleLen >= 80,
        }
      )}
      style={{
        backgroundImage: `url(${
          image?.filename
            ? ASSET_URL + '/' + image.filename
            : '/assets/images/telesoft-abstract-42.jpeg'
        })`,
      }}
    >
      <div className={`absolute inset-0 bg-black ${overlayClassName}`} />
      <div
        className={`absolute inset-0 bg-gradient-to-b from-telesoft to-transparent mix-blend-soft-light`}
      />

      <Container className="mb-8 w-full">
        <div className="max-w-4xl">
          <div className="flex justify-start items-start md:items-start">
            {hasIcon && (
              <div
                className={cn('', {
                  'pt-0.5 md:pt-2 lg:pt-5': titleLen <= 18,
                  'lg:pt-2.5': titleLen >= 18,
                })}
              >
                <IoMdReturnRight className="mr-2 lg:text-xl xl:text-2xl mt-2 md:mt-1.5 text-white" />
              </div>
            )}
            <Text
              variant={titleLen >= 18 ? 'pageHeading' : 'superHeading'}
              className={`text-left ${textColor}`}
            >
              {title}
            </Text>
          </div>
          {description && (
            <>
              <div
                className={`border-t border-white opacity-40 my-6 lg:my-7 xl:my-8`}
              />

              <div className="mt-2 lg:mt-4">
                <Text
                  variant="body"
                  className={cn(`text-left text-base md:text-lg ${textColor}`, {
                    // 'ml-6 lg:ml-8 xl:ml-10': hasIcon,
                  })}
                >
                  {description}
                </Text>
              </div>
            </>
          )}
        </div>
        {isButton && (
          <div className="my-8 flex flex-col md:flex-row justify-start items-start space-y-4 md:space-x-8 md:space-y-0">
            {buttons.map(({ link }: { link: any }, i: number) => {
              return (
                <div key={`btn--map--${i}`} className="w-full md:w-auto">
                  <CMSLink {...link}>
                    <Button invert className="text-white w-full" variant="cta">
                      {link.label}
                    </Button>
                  </CMSLink>
                </div>
              );
            })}
          </div>
        )}
      </Container>
    </div>
  );
}
