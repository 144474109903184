import Container from '@components/ui/container';
import React from 'react';
import serialise from './serialise';

const RicherText: React.FC<{
  className?: string;
  theme?: 'light' | 'dark';
  content: any;
  container?: boolean;
}> = ({ className, content, container = true, theme = 'dark' }) => {
  if (container) {
    return (
      <Container>
        <div
          className={`${className} ${
            theme === 'dark' ? 'text-heading' : 'text-white'
          }`}
        >
          {serialise(content)}
        </div>
      </Container>
    );
  } else {
    return <div className={`${className}`}>{serialise(content)}</div>;
  }
};

export default RicherText;
